<template>
  <b-card-code title="المكتبة الالكترونية" no-body>
    <b-card-body>
      <div class="d-flex justify-content-between flex-wrap">
        <!-- filter -->
        <b-form-group
          label="فلتر"
          label-cols-sm="1"
          label-align-sm="left"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="md">
            <b-form-input
              id="filterInput"
              v-model="title"
              type="search"
              placeholder="ابحث عن.."
            />
            <b-input-group-append>
              <b-button @click="resetSearch()"> الغاء الفلتر </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                v-b-modal.model-a-w
              >
                <feather-icon icon="FilePlusIcon" class="mr-50" />
                <span class="align-middle">اضافة كتاب او مؤلف جديد</span>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
      <b-form-row style="margin-top: 10px">
        <b-col md="6">
          <b-form-group>
            <v-select
              v-model="department_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="search.departments"
              placeholder="اختر قسم"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <v-select
              v-model="author_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="search.authors"
              placeholder="اختر مؤلف"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <v-select
              v-model="category_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="ar_title"
              :options="search.categories"
              placeholder="اختر تصنيف رئيسي"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <v-select
              v-model="type_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="ar_title"
              :options="search.types"
              placeholder="مؤلف او كتاب"
            />
          </b-form-group>
        </b-col>
        <b-col v-show="search.subs.length>0" md="6">
          <b-form-group>
            <v-select
              v-model="subcategory_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="ar_title"
              :options="search.subs"
              placeholder="اختر نوع ثانوي"
            />
          </b-form-group>
        </b-col>
      </b-form-row>
    </b-card-body>
<div class="demo-vertical-spacing">
      <b-progress
      v-if="precentage !== 0"
        :value="precentage"
        max="100"
      />
      </div>
    <b-table
      ref="table"
      striped
      hover
      responsive
      class="position-relative"
      :per-page="perPage"
      :items="items"
      :fields="fields"
    >
      <template #cell(action)="action">
        <b-dropdown
          variant="link"
          no-caret
          :right="$store.state.appConfig.isRTL"
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>
          <b-col md="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-warning"
              v-b-modal.model-e-w
              @click="eRM(action.item)"
            >
              <feather-icon icon="EditIcon" class="mr-50" />
            </b-button>
          </b-col>
          <b-col md="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-danger"
              v-b-modal.modal-center
              @click="dRM(action.item)"
            >
              <feather-icon icon="Trash2Icon" class="mr-50" />
            </b-button>
          </b-col>
        </b-dropdown>
      </template>
      <template #cell(image)="image">
        <b-avatar size="lg" :src="getImage(image.item.image)" />
      </template>
      <template #cell(link)="link">
        <b-button v-if="link.item.type.id==3"
          v-ripple.400="'rgba(0, 207, 232, 0.15)'"
          variant="outline-info"
          @click="onClicked(link.item)"
        >
          عرض
        </b-button>
        <b-button v-else
          v-ripple.400="'rgba(0, 207, 232, 0.15)'"
          variant="outline-info"
          disabled
          @click="onClicked(link.item)"
        >
        عرض
        </b-button>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <b-form-group
        label="Per Page"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          @change="check()"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
        class="mb-0"
        @change="check()"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </b-card-body>
    <b-modal
      id="model-e-w"
      ok-title="حفظ التعديلات"
      centered
      size="lg"
      title="تعديل كتاب او مؤلف"
      @show="checkModel"
      @ok="uM"
    >
      <b-form novalidate class="needs-validation">
        <b-form-row>
        <b-col md="12">
          <b-form-group>
            <v-select
              v-model="editform.department_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="departments"
              placeholder="اختر قسم"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group>
            <v-select
              v-model="editform.author_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="authors"
              placeholder="اختر المؤلف"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group>
            <v-select
              v-model="editform.category_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="ar_title"
              :options="categories"
              placeholder="اختر التصنيف الرئيسي"
            />
          </b-form-group>
        </b-col>
        <b-col v-show="editsubs.length>0" md="12">
          <b-form-group>
            <v-select
              v-model="editform.subcategory_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="ar_title"
              :options="editsubs"
              placeholder="اختر التصنيف الفرعي"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group>
            <v-select
              v-model="editform.type_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="ar_title"
              :options="types"
              placeholder="مؤلف او كتاب ؟"
            />
          </b-form-group>
        </b-col>
          <b-col md="12">
            <label for="input-valid1">العنوان بالانكليزي:</label>
            <b-form-input
              id="input-valid1"
              v-model="editform.en_title"
              :state="editform.en_title.length > 0"
              placeholder="العنوان بالانكليزي"
            />
          </b-col>
          <b-col md="12">
            <label for="input-valid1">السنة :</label>
            <b-form-input
              id="input-valid1"
              v-model="editform.year"
              placeholder="السنة"
            />
          </b-col>

          <b-col md="12">
            <label for="input-invalid1">تفاصبل الكتاب بالعربي:</label>
            <b-form-textarea
              id="textarea-default"
              v-model="editform.ar_description"
              placeholder="تفاصبل الكتاب بالعربي"
              rows="3"
            />
          </b-col>
          <b-col md="12">
            <label for="input-invalid1">تفاصبل الكتاب بالانكليزي:</label>
            <b-form-textarea
              id="textarea-default"
              v-model="editform.en_description"
              placeholder="تفاصبل الكتاب بالانكليزي"
              rows="3"
            />
          </b-col>
          <b-col md="12">
            <label for="input-valid1">اختر صورة الكتاب او المؤلف:</label>
            <b-form-file
            placeholder="اختر الصورة او اسقطها هنا..."
            drop-placeholder="اختر الصورة او اسقطها هنا..."
              v-model="editform.image"
              @change="selectImageForEditForm"
            />
          </b-col>
          <b-col md="12">
            <label for="input-valid1">اختر ملف الكتاب (pdf.)></label>
            <b-form-file
            placeholder="اختر الملف او اسقطها هنا..."
            drop-placeholder="اختر الملف او اسقطها هنا..."
              v-model="editform.file"
              @change="selectFileForEditForm"
            />
          </b-col>
        </b-form-row>
      </b-form>
    </b-modal>
    <b-modal
      id="model-a-w"
      ok-title="حفظ البيانات"
      centered
      size="lg"
      title="اضافة كتاب او مؤلف"
      @show="checkModel"
      @ok="aNM"
    >
      <b-form novalidate class="needs-validation">
        <b-form-row>
        <b-col md="12">
          <b-form-group>
            <v-select
              v-model="form.department_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="departments"
              placeholder="اختر قسم"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group>
            <v-select
              v-model="form.author_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="authors"
              placeholder="اختر المؤلف"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group>
            <v-select
              v-model="form.category_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="ar_title"
              :options="categories"
              placeholder="اختر التصنيف الرئيسي"
            />
          </b-form-group>
        </b-col>
        <b-col v-show="subs.length>0" md="12">
          <b-form-group>
            <v-select
              v-model="form.subcategory_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="ar_title"
              :options="subs"
              placeholder="اختر التصنيف الفرعي"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group>
            <v-select
              v-model="form.part_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="ar_title"
              :options="parts"
              placeholder="اختر الجزء"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group>
            <v-select
              v-model="form.type_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="ar_title"
              :options="types"
              placeholder="مؤلف او كتاب ؟"
            />
          </b-form-group>
        </b-col>
          <b-col md="12">
            <label for="input-valid1">العنوان بالعربي:</label>
            <b-form-input
              id="input-valid1"
              v-model="form.ar_title"
              :state="form.ar_title.length > 0"
              placeholder="العنوان بالعربي"
            />
          </b-col>
          <b-col md="12">
            <label for="input-valid1">العنوان بالانكليزي:</label>
            <b-form-input
              id="input-valid1"
              v-model="form.en_title"
              :state="form.en_title.length > 0"
              placeholder="العنوان بالانكليزي"
            />
          </b-col>
          <b-col md="12">
            <label for="input-valid1">السنة:</label>
            <b-form-input
              id="input-valid1"
              v-model="form.year"
              placeholder="السنة"
            />
          </b-col>

          <b-col md="12">
            <label for="input-invalid1">تفاصبل الكتاب بالعربي:</label>
            <b-form-textarea
              id="textarea-default"
              v-model="form.ar_description"
              :state="form.ar_description.length > 0"
              placeholder="تفاصبل الكتاب بالعربي"
              rows="3"
            />
          </b-col>
          <b-col md="12">
            <label for="input-invalid1">تفاصبل الكتاب بالانكليزي:</label>
            <b-form-textarea
              id="textarea-default"
              v-model="form.en_description"
              :state="form.en_description.length > 0"
              placeholder="تفاصبل الكتاب بالانكليزي"
              rows="3"
            />
          </b-col>
          <b-col md="12">
            <label for="input-valid1">اختر صورة الكتاب او المؤلف:</label>
            <b-form-file
            placeholder="اختر الصورة او اسقطها هنا..."
            drop-placeholder="اختر الصورة او اسقطها هنا..."
              v-model="form.image"
              @change="selectImageForForm"
            />
          </b-col>
          <b-col md="12">
            <label for="input-valid1">اختر الملف:</label>
            <b-form-file
            placeholder="اختر الملف او اسقطها هنا..."
            drop-placeholder="اختر الملف او اسقطها هنا..."
              v-model="form.file"
              @change="selectFileForForm"
            />
          </b-col>
        </b-form-row>
      </b-form>
    </b-modal>

    <b-modal
      id="modal-center"
      centered
      title="حذف الكتاب او المؤلف"
      @ok="dM"
      ok-title="حذف الحقل"
    >
      <b-card-text>
        هل تريد حذف هذه الحقل " {{ this.editform.ar_title }} "
        ?
      </b-card-text>
    </b-modal>
  </b-card-code>
</template>

<script>
import vSelect from "vue-select";
import store from "@/store";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import { filesUrl } from "@/main.js";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BFormTextarea,
  BTable,
  BFormTags,
  BAvatar,
  BModal,
  BDropdown,
  BDropdownItem,
  BFormCheckboxGroup,
  BForm,
  BFormRow,
  BCol,
  VBModal,
  BBadge,
  BFormValidFeedback,
  BFormInvalidFeedback,
  BFormGroup,
  BFormCheckbox,
  BCardText,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCardBody,BProgress,
  BFormFile,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BCardCode,
    BTable,
    BForm,
    vSelect,
    BAvatar,BProgress,
    BBadge,BFormTextarea,
    BFormTags,
    BFormRow,
    BDropdown,
    filesUrl,
    BDropdownItem,
    BFormGroup,
    togglePasswordVisibility,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormCheckboxGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BModal,
    VBModal,
    Ripple,
    BCol,
    BFormCheckbox,
    BCardText,
    BFormValidFeedback,
    BFormInvalidFeedback,
    BFormFile,
  },
  data() {
    return {
      precentage:0,
      teacher_id: "",
      perPage: 3,
      pageOptions: [3, 5, 10, 100],
      totalRows: 1,
      currentPage: 0,
      department: "",
      title: "",
      filter: null,
      department_id:"",
      author_id:"",
      category_id:"",
      type_id:"",
      subcategory_id:"",
      filterOn: [],
      departments: [],
      authors: [],
      categories: [],
      parts: [],
      types: [],
      subs: [],
      editsubs: [],
      notifi: {
        route: "",
        title: "",
        item_id: "",
        table: "",
        users: [],
        departments: [],
        role_id: "",
        type: "",
        subtitle: "",
        bg: "",
      },
      search:{
        departments: [],
        authors: [],
        categories: [],
        types: [],
        subs: [],
      },
      fields: [
        {
          key: "ar_title",
          label: "العنوان بالعربي",
        },
        {
          key: "en_title",
          label: "العنوان بالانكليزي",
        },
        {
          key: "department.ar_name",
          label: "القسم",
        },
        {
          key: "author.ar_title",
          label: "المؤلف",
        },
        {
          key: "parts[0].ar_title",
          label: "جزء",
        },
        {
          key: "type.ar_title",
          label: "مؤلف او كتاب",
        },
        {
          key: "category.ar_title",
          label: "تصنيف رئيسي",
        },
        {
          key: "subcategory.ar_title",
          label: "تصنيف فرعي",
        },
        {
          key: "year",
          label: "سنة",
        },
        { key: "downloads", label: "التحميلات " },
        { key: "link", label: "الرابط " },
        { key: "image", label: "الصورة " },
        { key: "action", label: "الاجراءات" },
      ],
      items: [],
      form: {
        en_title: "",
        ar_title: "",
        ar_description: "",
        en_description: "",
        file: "",
        image: "",
        department_id: "",
        author_id: "",
        category_id: "",
        subcategory_id: "",
        part_id: "",
        type_id: "",
        year: "",
      },
      editform: {
        en_title: "",
        ar_title: "",
        ar_description: "",
        en_description: "",
        file: "",
        image: "",
        department_id: "",
        author_id: "",
        category_id: "",
        subcategory_id: "",
        year: "",
        type_id: "",
        id: null,
      },
      selected: [],
      selected2: "",
      formModal: {},
      snowOption: {
        theme: "snow",
      },
      slider:"",
    };
  },
  watch: {
    department: function (val) {
      this.gS();
    },
    title: function (val) {
      this.gS();
    },
    department_id: function (val) {
      this.gS();
    },
    author_id: function (val) {
      this.gS();
    },
    category_id: function (val) {
      this.gSM7(),this.gS();
    },
    type_id: function (val) {
      this.gS();
    },
    "form.category_id": function (val) {
      this.gSM5();
    },
    "editform.category_id": function (val) {
      this.gSM6();
    },
    subcategory_id: function (val) {
      this.gS();
    },
    "$route.params.id": {
      handler: function (search) {
        this.gS();
      },
    },
    

  },
  mixins: [togglePasswordVisibility],
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  directives: {
    Ripple,
  },
  mounted() {
    this.gS(), this.gSM(), 
    this.gSM1(), this.gSM2(), this.gSM3(), this.gSM4();
  },
  methods: {
    async gSM() {
      await this.axios
        .get(
          `since/deparment?take=100&skip=0&select=true&type=5`
        )
        .then((res) => {
          this.departments = [];
          this.departments = res.data.items;
          this.search.departments = res.data.items;
        })
    },
    async gSM1() {
      await this.axios
        .get(
          `authors?take=100&skip=0`
        )
        .then((res) => {
          this.authors = [];
          this.authors = res.data.items;
          this.search.authors = res.data.items;
        })
    },
    async gSM2() {
      await this.axios
        .get(
          `categories?take=100&skip=0&select=true`
        )
        .then((res) => {
          this.categories = [];
          this.categories = res.data.items;
          this.search.categories = res.data.items;
        })
    },
    async gSM3() {
      await this.axios
        .get(
          `parts?take=100&skip=${
            this.currentPage - 1
          }`
        )
        .then((res) => {
          this.parts = [];
          this.parts = res.data.items;
        })
    },
    async gSM4() {
      await this.axios
        .get(
          `types?library=true`
        )
        .then((res) => {
          this.types = [];
          this.types = res.data.items;
          this.search.types = res.data.items;
        })
    },
    async gSM5() {
      if (isNaN(this.form.category_id)) {
        this.form.category_id = this.form.category_id["id"];
      }
      await this.axios
        .get(
          `subcategory?take=100&skip=${
            this.currentPage - 1
          }&category_id=${this.form.category_id}`
        )
        .then((res) => {
          this.subs = [];
          this.subs = res.data.items;
        })
    },
    async gSM6() {
      if (isNaN(this.editform.category_id)) {
        this.editform.category_id = this.editform.category_id["id"];
      }
      await this.axios
        .get(
          `subcategory?take=100&skip=${
            this.currentPage - 1
          }&category_id=${this.editform.category_id}`
        )
        .then((res) => {
          this.editform.subcategory_id ="";
          this.editsubs = [];
          this.editsubs = res.data.items;
        })
    },
    async gSM7() {
      if (isNaN(this.category_id)) {
        this.category_id = this.category_id["id"];
      }
      await this.axios
        .get(
          `subcategory?take=100&skip=0&category_id=${this.category_id}`
        )
        .then((res) => {
          this.search.subs = [];
          this.search.subs = res.data.items;
        })
    },
    checkModel() {
      this.selected = [];
    },
    resetCheckboxes() {
      this.selected = [];
    },
    async uM() {
      if (isNaN(this.editform.department_id)) {
        this.editform.department_id = this.editform.department_id["id"];
      }
      if (isNaN(this.editform.author_id)) {
        this.editform.author_id = this.editform.author_id["id"];
      }
      if (isNaN(this.editform.category_id)) {
        this.editform.category_id = this.editform.category_id["id"];
      }
      if (isNaN(this.editform.type_id)) {
        this.editform.type_id = this.editform.type_id["id"];
      }
      if (isNaN(this.editform.subcategory_id)) {
        this.editform.subcategory_id = this.editform.subcategory_id["id"];
      }
      let data = new FormData();
      data.append("en_title", this.editform.en_title);
      data.append("ar_title", this.editform.ar_title);
      data.append("ar_description", this.editform.ar_description);
      data.append("en_description", this.editform.en_description);
      data.append("image", this.editform.image);
      data.append("year", this.editform.year);
      data.append("file", this.editform.file);
      if(this.editform.department_id!==null){
      data.append("department_id", this.editform.department_id);
      }
      if(this.editform.author_id!==null){
      data.append("author_id", this.editform.author_id);
      }
      if(this.editform.category_id!==null){
      data.append("category_id", this.editform.category_id);
      }
      if(this.editform.subcategory_id!==null){
      data.append("subcategory_id", this.editform.subcategory_id);
      }
      if(this.editform.part_id!==null){
      data.append("part_id", this.editform.part_id);
      }
      if(this.editform.type_id!==null){
      data.append("type_id", this.editform.type_id);
      }
      await this.axios
        .post(`libraries/${this.editform.id}`, data,{
          onUploadProgress: progressEvent => {
            this.precentage =
              (progressEvent.loaded / progressEvent.total) * 100;
          }
        })
        .then((res) => {
          if (res.data.errors) {
            this.errorToast();
          } else {
            this.gS();
            this.makeToast(
              "success",
              "update Success",
              "Your update is done successfully"
            );
            this.notifi.route = "library-notif";
            this.notifi.title = "update action";
            this.notifi.item_id = res.data.data.id;
            this.notifi.table = "library";
            this.notifi.role_id = 1;
            this.notifi.type = "light-worning";
            this.notifi.subtitle = "updated the library";
            this.notifi.bg = "bg-worning";
            this.sendNoti();
          }
          this.precentage=0
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "warning",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    
    },
    async aNM() {
      if (isNaN(this.form.department_id)) {
        this.form.department_id = this.form.department_id["id"];
      }
      if (isNaN(this.form.author_id)) {
        this.form.author_id = this.form.author_id["id"];
      }
      if (isNaN(this.form.category_id)) {
        this.form.category_id = this.form.category_id["id"];
      }
      if (isNaN(this.form.subcategory_id)) {
        this.form.subcategory_id = this.form.subcategory_id["id"];
      }
      if (isNaN(this.form.part_id)) {
        this.form.part_id = this.form.part_id["id"];
      }
      if (isNaN(this.form.type_id)) {
        this.form.type_id = this.form.type_id["id"];
      }
      
      let data = new FormData();
      data.append("en_title", this.form.en_title);
      data.append("ar_title", this.form.ar_title);
      data.append("ar_description", this.form.ar_description);
      data.append("en_description", this.form.en_description);
      data.append("image", this.form.image);
      data.append("year", this.form.year);
      data.append("file", this.form.file);
      data.append("department_id", this.form.department_id);
      data.append("author_id", this.form.author_id);
      data.append("category_id", this.form.category_id);
      data.append("subcategory_id", this.form.subcategory_id);
      data.append("part_id", this.form.part_id);
      data.append("type_id", this.form.type_id);
      await this.axios
        .post(`libraries`, data,{
          onUploadProgress: progressEvent => {
            this.precentage =
              (progressEvent.loaded / progressEvent.total) * 100;
          }
        })
        .then((res) => {
          if (res.data.errors) {
            this.errorToast();
          } else {
            this.gS();
            this.makeToast(
              "success",
              "update Success",
              "Your model is added successfully"
            );
          }
          this.rF();
          this.notifi.route = "library-notif";
            this.notifi.title = "add action";
            this.notifi.item_id = res.data.data.id;
            this.notifi.table = "library";
            this.notifi.role_id = 1;
            this.notifi.type = "light-Success";
            this.notifi.subtitle = "added new book";
            this.notifi.bg = "bg-success";
            this.sendNoti();
            this.precentage=0
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "warning",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
      this.rF();
    },
    rF() {
      (this.form.en_title = ""),
        (this.form.ar_title = ""),
        (this.form.file = ""),
        (this.form.image = ""),
        (this.form.ar_description = ""),
        (this.form.en_description = ""),
        (this.form.author_id = ""),
        (this.form.category_id = ""),
        (this.form.subcategory_id = ""),
        (this.form.part_id = ""),
        (this.form.type_id = ""),
        (this.form.department_id = "");
    },
    rEF() {
      (this.editform.en_title = ""),
        (this.editform.ar_title = ""),
        (this.editform.image = ""),
        (this.editform.file = ""),
        (this.editform.ar_description = ""),
        (this.editform.en_description = ""),
        (this.editform.department_id = ""),
        (this.editform.author_id = ""),
        (this.editform.category_id = ""),
        (this.editform.subcategory_id = ""),
        (this.editform.part_id = ""),
        (this.editform.type_id = ""),
        (this.editform.id = "");
    },
    async dM() {
      await this.axios
        .delete(`libraries/${this.editform.id}`)
        .then((res) => {
          if (res.data.errors) {
            this.errorToast();
          } else {
            this.gS();
            this.makeToast(
              "success",
              "Delete Success",
              "Your item is deleted successfully"
            );
            this.notifi.route = "library-notif";
            this.notifi.title = "delete action";
            this.notifi.item_id = res.data.data.id;
            this.notifi.table = "library";
            this.notifi.role_id = 1;
            this.notifi.type = "light-danger";
            this.notifi.subtitle = "deleted book";
            this.notifi.bg = "bg-danger";
            this.sendNoti();
          }
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "warning",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    errorToast() {
      this.makeToast(
        "danger",
        "update failed",
        "some thing want wrong, i`m sorry :("
      );
    },
    async sendNoti() {
      await this.axios.post(`notifications`, this.notifi);
    },
    eRM(item) {
      console.log(item)
      this.editform.en_title = item.en_title;
      this.editform.ar_title = item.ar_title;
      this.editform.ar_description = item.ar_description;
      this.editform.en_description = item.en_description;
      this.editform.author_id = item.author_id;
      this.editform.category_id = item.category_id;
      this.editform.year = item.year;
      this.editform.subcategory_id = item.subcategory_id;
      this.editform.type_id = item.type_id;
      this.editform.department_id = item.department_id;
      this.editform.id = item.id;
    },
    dRM(item) {
      this.editform.ar_title = item.ar_title;
      this.editform.id = item.id;
    },
    check() {
      setTimeout(() => this.gS(), 1000);
    },
    async gS() {
      await this.axios
        .get(
          `libraries?take=${this.perPage}&skip=${this.currentPage - 1}&show=${this.$route.params.id}&title=${this.title}&department_id=${this.department_id.id}&author_id=${this.author_id.id}&category_id=${this.category_id}&subcategory_id=${this.subcategory_id.id}&type_id=${this.type_id.id}`
        )
        .then((res) => {
          this.items = [];
          this.totalRows = "";
          this.totalRows = res.data.totalCount;
          this.items = res.data.items;
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    makeToast(variant = null, title = null, body = null) {
      this.$bvToast.toast(body, {
        title: title,
        variant,
        solid: false,
      });
    },
    resetSearch() {
      (this.title = ""),
      (this.department_id = ""),
      (this.author_id = ""),
      (this.category_id = ""),
      (this.type_id = "");
      (this.subcategory_id = "");
      this.gS();
    },
    getImage(path) {
      return `${filesUrl}/${path}`;
    },
    selectImageForForm(e) {
      this.form.image = "";
      this.form.image = e.target.files[0];
    },
    selectImageForEditForm(e) {
      this.editform.image = "";
      this.editform.image = e.target.files[0];
    },
    selectFileForForm(e) {
      this.form.file = "";
      this.form.file = e.target.files[0];
    },
    selectFileForEditForm(e) {
      this.editform.file = "";
      this.editform.file = e.target.files[0];
    },
    onClicked(path) {
      window.open(`${filesUrl}/${path.file}`, "_blank");
    },
  },
};
</script>
<style lang="scss">
</style>